import moment from "moment";
import { formatRelative, parseISO } from "date-fns";
import CredentialStorage, { UserInfoFormData } from "./CredentialStorage";
import EnvironmentConfig from "../component/config/EnvironmentConfig";
import { storageKeys, supportRequest, common } from "./constants";
import { AnswerType } from "../component/common/JournalEntry/JournalEntry";
import { ApiLesson, Sections } from "../interfaces/ApiTypes";
import { endOfWeek, isWithinInterval, startOfWeek } from 'date-fns'

//Function for create channel-name in sendbird-chat
function channelName(): string {
  const currentDate = moment();
  const day = currentDate.date();
  const year = currentDate.year();
  const monthName = currentDate.format("MMMM");
  const firstName = localStorage.getItem(storageKeys.FirstName) || "";
  const channelName = `${firstName} ${monthName} ${day}, ${year}`;
  return channelName;
}

//Function to calcualte age of User
export function calculateAge(birthdate: string | null): number {
  const birthDate = moment(birthdate, "DD-MM-YYYY");
  const age = moment().diff(birthDate, "years");
  return age;
}

//Function to get avatar in chat
export const imageSrc = (msgSender: any) => {
  const userId: string | null = localStorage.getItem(storageKeys.UserID);
  if (msgSender?.userId === userId) {
    return `${EnvironmentConfig.imageBaseURL}user.png`;
  } else {
    return `${EnvironmentConfig.imageBaseURL}coach-img.png`;
  }
};

//Function to get date of tickets
export function getFormattedDate(date: Date | string) {
  const momentDate = moment(date);
  const month = momentDate.format("MMMM");
  const day = momentDate.format("DD");
  const year = momentDate.year();
  return `${month}, ${day} ${year}`;
}

export function formateDate(timestamp: any) {
  const date = new Date(timestamp).toISOString();
  const formattedRelativeTime = formatRelative(parseISO(date), new Date(), {
    weekStartsOn: 1,
  });
  return formattedRelativeTime;
}

//Function get user-information for creatinf ticket
export function getTicketData(eapxData: UserInfoFormData) {
  let eapxValue = CredentialStorage.eapxValues(eapxData);
  return {
    channel_name: channelName(),
    first_name: localStorage.getItem(storageKeys.FirstName),
    message_content:
      supportRequest.STATIC_MESSAGE +
      localStorage.getItem(storageKeys.TextCoachingQuestion),
    gender: eapxValue.gender,
    phone_number: eapxValue.phone_number,
    state: eapxValue.state,
    company: eapxValue.company,
  };
}

//Function to get Queryparameters for redirection-url
export function getQueryParams(formData: UserInfoFormData) {
  const firstName: string | null =
    localStorage.getItem(storageKeys.FirstName) || "";
  const lastName: string | null =
    localStorage.getItem(storageKeys.LastName) || "";
  const email: string | null = localStorage.getItem(storageKeys.Email) || "";
  const company: string | null =
    localStorage.getItem(storageKeys.Company) || "";
  const phone = formData.phone;
  const queryParams = `?firstName=${encodeURIComponent(
    firstName
  )}&lastName=${encodeURIComponent(lastName)}&email=${encodeURIComponent(
    email
  )}&phone=${encodeURIComponent(phone)}&${EnvironmentConfig.companyNameField
    }=${encodeURIComponent(company)}`;

  return queryParams;
}

export function transformString(str: string) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
    .join("-");
}

export function getSimplifiedUserName(input: string): string {
  const parts: string[] = input.split("_");
  const filteredParts: string[] = parts.filter((part) => Number.isNaN(Number(part)));
  const capitalizedParts: string[] = filteredParts.map((part) => {
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  });
  return capitalizedParts.join(" ");
}

export const getClassNameForService = (
  serviceType: string,
  activeSection: string,
  sectionName: string,
  menu: boolean
) => {
  const activeClass = menu ? "active-menu" : "active";
  const commonClass = "menu-items";

  switch (sectionName) {
    case "service1":
      return serviceType?.includes(common.TEXT_COACHING)
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    case "service2":
      return serviceType?.includes(common.SELF_SCHEDULING)
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    case "service3":
      return EnvironmentConfig.showWorkLifeRequest &&
        (serviceType?.includes(common.TEXT_COACHING) ||
          serviceType?.includes(common.SELF_SCHEDULING))
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    case "service4":
      return serviceType?.includes(common.TEXT_COACHING)
        ? activeSection === sectionName
          ? activeClass
          : commonClass
        : "hide-div";
    default:
      return "hide-div";
  }
};

export const getClassNameForServiceVisibility = (
  serviceType: any,
  condition: any,
  isSpecialCondition = false
) => {
  if (isSpecialCondition) {
    return EnvironmentConfig.showWorkLifeRequest &&
      (serviceType?.includes(condition[0]) ||
        serviceType?.includes(condition[1]))
      ? "show-div"
      : "hide-div";
  }
  return serviceType?.includes(condition) ? "show-div" : "hide-div";
};

interface QuestionObject {
  question: string;
  answer: string;
}

//Function to capitalize first letter of word
export function capitalizeFirstLetter(word: string) {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const validateAnswers = (data: AnswerType[]): string | null => {
  for (const { question, isRequired, answer } of data) {
    if (isRequired) {
      if (answer === null || (typeof answer === 'object' && Object.keys(answer).length === 0) || answer === '') {
        return `${question} is required`;
      }
    }
  }
  return null;
};

export const validateChartedJournalAnswers = (data: AnswerType[]): string | null => {
  for (const { question, isRequired, answer } of data) {
    if (isRequired) {
      if (answer === null || (typeof answer === 'object' && Object.keys(answer).length === 0) || answer === '') {
        return `${question} is required`;
      }
      if (typeof answer === 'object') {
        if ('date' in answer && (!answer.date)) {
          return `Date is required`;
        }
        if ('time' in answer && (!answer.time || answer.time === '')) {
          return `Time slot is required`;
        }
      }
    }
  }
  return null;
};

export const formatTime = (timeString: string) => {
  const newString = new Date(timeString);
  const hours = newString.getHours();
  const minutes = newString.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
  return formattedTime;
};

export function findFirstIncompleteLesson(data: Sections[]) {
  for (const item of data) {
    const firstIncompleteLesson = item.lessons.find(lesson => !lesson.isCompleted);
    if (firstIncompleteLesson) {
      return {
        sectionId: item.id,
        lessonId: firstIncompleteLesson.id
      };
    }
  }
  return null;
}

export function getNextLesson(data: Sections[], currentLessonId: number) {
  let allLessons: { sectionId: number; lessonId: number }[] = [];

  const sortedSections = [...data].sort((a, b) => a.order - b.order);

  for (const section of sortedSections) {
    const sortedLessons = [...section.lessons].sort((a, b) => a.order - b.order);
    for (const lesson of sortedLessons) {
      allLessons.push({
        sectionId: section.id,
        lessonId: lesson.id
      });
    }
  }

  for (let i = 0; i < allLessons.length - 1; i++) {
    if (allLessons[i].lessonId === currentLessonId) {
      return allLessons[i + 1];
    }
  }

  return null;
}


export function generateDateForDay(day: string): string {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayIndex = daysOfWeek.indexOf(day);
  if (dayIndex === -1) {
    return moment().format('MM/DD/YY');
  }
  const today = moment();
  const targetDate = today.isoWeekday(dayIndex);
  return targetDate.format('MM/DD/YY');
};

export function findSectionObjectById(lessonsArray: Sections[], lessonId: number) {
  for (const lessonGroup of lessonsArray) {
    if (lessonGroup.lessons && lessonGroup?.lessons) {
      const foundLesson = lessonGroup?.lessons?.find((lesson: ApiLesson) => lesson.id === lessonId);
      if (foundLesson) {
        return { lessonGroup, lesson: foundLesson };
      }
    }
  }
  return null;
}
export const isDateWithinCurrentWeek = (date: Date | null): boolean => {
  if (!date) return false;
  const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
  const currentWeekEnd = endOfWeek(new Date(), { weekStartsOn: 1 });
  return isWithinInterval(date, { start: currentWeekStart, end: currentWeekEnd });
};
