import React from 'react';
import './LessonAndSidebar.scss'
import ModuleSidebar from '../SideTabs/module-sidebar/ModuleSidebar'
import Loader from '../Loader/Loader';
import LessonModule from './LessonModule';
import { ApiLesson, Sections } from '../../../interfaces/ApiTypes';

interface Props {
  moduleId: number;
  cbtModuleSections: Sections[];
  sectionId: number | null;
  selectedLessonId: number | null;
  setSectionId: (id: number | null) => void;
  setSelectedLessonId: (id: number | null) => void;
  lessonDetails: ApiLesson | null;
  isLessonFetching: boolean;
  isSectionLoading: boolean;
  onLessonUpdate: () => void;
  onNextButtonClick: (lesson: ApiLesson, status: boolean) => void;
  onSidebarLessonClick: (id: ApiLesson) => void;
}

const LessonAndSidebar: React.FC<Props> = (props) => {

  return (
    <>
      {props.isSectionLoading ? (
        <Loader />
      ) : (
        <>
          {props.cbtModuleSections && (
            <div className="lesson-sidebar-component">
              <div className="sidebar-section">
                <ModuleSidebar
                  id={Number(props.moduleId)}
                  sectionId={props.sectionId}
                  setSectionId={props.setSectionId}
                  currentLessonId={props.selectedLessonId}
                  cbtModuleSections={props.cbtModuleSections}
                  onSidebarLessonClick={props.onSidebarLessonClick}
                />
              </div>
              <div className="module-content-grid">
                {props.lessonDetails && (
                  <LessonModule
                    moduleId={Number(props.moduleId)}
                    onLessonUpdated={props.onLessonUpdate}
                    isLessonFetching={props.isLessonFetching}
                    currentLessonDetails={props.lessonDetails}
                    onNextButtonClick={props.onNextButtonClick}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default LessonAndSidebar;