import React, { useState, useRef, useEffect } from "react";
import { INTERNAL_APPLICATION_ERROR, Signup } from '../../utils/member-portal/constant'
import { common, signup, storageKeys } from '../../utils/constants'
import { validate, ValidationError } from './validateForm'
import { useNavigate, useLocation } from "react-router-dom";
import './SignUp.scss'
import '../../styles/authFormsStyles.scss'
import { getOrganizationDetail, register } from '../../utils/ApiClient'
import Loader from "../../component/common/Loader/Loader";
import CredentialStorage from "../../utils/CredentialStorage";
import useGlobalState from "../../context/useGlobalState";
import { ROUTES } from "../../utils/routes";

const SignUp = () => {
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
    });
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorField, setErrorField] = useState<string | null>()
    const firstnameRef = useRef<HTMLInputElement>(null);
    const lastnameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const organizationId = localStorage.getItem(storageKeys.OrganizationID);
    const organizationName = localStorage.getItem(storageKeys.Company);
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search);
    const oId: string | null = searchParam.get("oid");
    const { globalErrorHandler, loader, setLoader } = useGlobalState();
    const navigate = useNavigate()

    const getEAPXOrganizationDetail = async (oId: string | null): Promise<void> => {
        try {
            if (oId) {
                const response = await getOrganizationDetail(oId);
                localStorage.setItem(storageKeys.Company, response.data.name);
                localStorage.setItem(storageKeys.OrganizationID, response.data.id);
                setLoader(false)
            } else {
                setLoader(false)
                globalErrorHandler(INTERNAL_APPLICATION_ERROR)
            }
        } catch (error: any) {
            setLoader(false)
            globalErrorHandler(error);
        }
    };

    useEffect(() => {
        if (oId) {
            getEAPXOrganizationDetail(oId);
        }
        else {
            setLoader(false)
        }
    }, [oId]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError(false);
        const { name, value } = e.currentTarget;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const registerUser = async (): Promise<void> => {
        setLoading(true)
        try {
            const data = {
                first_name: formData.firstname,
                last_name: formData.lastname,
                email: formData.email,
                organization_id: organizationId,
                organization_name: organizationName
            }
            const response = await register(data)
            CredentialStorage.login(response.data);
            setLoading(false)
            navigate(ROUTES.HOME)
        } catch (error) {
            setLoading(false)
            globalErrorHandler(error)
        }
    }

    const handleSubmit = (e: React.FormEvent, formData: { firstname: string; lastname: string; email: string }) => {
        e.preventDefault();
        const hasError: ValidationError | null = validate(formData)
        if (hasError) {
            setError(true);
            setErrorMessage(hasError.message)
            if (hasError.field === "firstname") {
                setErrorField("firstname")
                firstnameRef.current?.focus();
            } else if (hasError.field === "lastname") {
                setErrorField("lastname")
                lastnameRef.current?.focus();
            } else if (hasError.field === "email") {
                setErrorField("email")
                emailRef.current?.focus();
            }
        } else {
            setErrorField(null)
            registerUser()
        }
    };

    return (
        <div className='member-signup-page'>
            {
                loader ? <Loader /> : <>
                    {
                        !organizationId ? <span className="invitaion-msg">
                            You must have an invitation URL from your company to register
                        </span> : <>
                            {
                                loading ? <Loader /> : <div className='member-signup-page'>
                                    <span className="title">{Signup.title}</span>
                                    <span className="subtitle">{Signup.subtitle}</span>
                                    <form className="signup-form">
                                        <div className="form-input">
                                            <div className="input-div">
                                                <label>{common.FIRST_NAME}</label>
                                                <input
                                                    type="text"
                                                    name="firstname"
                                                    className={`signup-page-input ${errorMessage && errorField === "firstname" ? "error-outline" : ""}`}
                                                    placeholder={common.FIRST_NAME_PLACEHOLDER}
                                                    onChange={(e) => handleInputChange(e)}
                                                    ref={firstnameRef}
                                                />
                                            </div>
                                            <div className="input-div">
                                                <label>{common.LAST_NAME}</label>
                                                <input
                                                    type="text"
                                                    name="lastname"
                                                    className={`signup-page-input ${errorMessage && errorField === "lastname" ? "error-outline" : ""}`}
                                                    placeholder={common.LAST_NAME_PLACEHOLDER}
                                                    onChange={(e) => handleInputChange(e)}
                                                    ref={lastnameRef}
                                                />
                                            </div>
                                        </div>
                                        <div className="input-div">
                                            <label>{signup.EMAIL_ADDRESS}</label>
                                            <input
                                                type="email"
                                                name="email"
                                                className={`signup-page-email-input ${errorMessage && errorField === "email" ? "error-outline" : ""}`}
                                                placeholder={signup.EMAIL_ADDRESS_PLACEHOLDER}
                                                onChange={(e) => handleInputChange(e)}
                                                ref={emailRef}
                                            />
                                        </div>
                                        {error && <div className="error">*{errorMessage}*</div>}
                                        <button
                                            onClick={(e) => handleSubmit(e, formData)}
                                            className="large-green-button">
                                            {Signup.button}
                                        </button>
                                        <span className="nav-link nav-margin" onClick={() => navigate(ROUTES.LOGIN)}>
                                            {Signup.navlink}
                                        </span>
                                    </form></div>
                            }
                        </>
                    }
                </>
            }
        </div>
    )
}

export default SignUp