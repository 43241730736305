import { useNavigate } from 'react-router-dom';
import { WorkLifeSuccessPage } from '../../../../utils/constants';
import './SuccessMessage.scss'
import { ROUTES } from '../../../../utils/routes';

const SuccessMessage = () => {
  const navigate = useNavigate()

  return (
    <div className="success-message-page">
      <span className="subtitle">{WorkLifeSuccessPage.TITLE}</span>
      <span className="sub-title">{WorkLifeSuccessPage.SUBTITLE}</span>
      <span className="message-content">If you have an urgent need related to domestic violence, homelessness or a natural disaster, please call your EAP at: <span className='highlighted-text'>(# depends on client company)</span> to receive immediate assistance.</span>
      <button className='question-button-yes' onClick={() => navigate(ROUTES.INDIVIDUAL_SUPPORT)}>{"Back to Individual Support"}</button>
    </div>
  );
};

export default SuccessMessage;
