import React, { useState } from 'react'
import { logIn } from "../../utils/ApiClient";
import { useNavigate } from 'react-router-dom';
import { Login } from '../../utils/member-portal/constant';
import './Login.scss'
import '../../styles/authFormsStyles.scss'
import SuccessMessage from '../../component/common/SuccessMessage/SuccessMessage';
import { ROUTES } from '../../utils/routes';

const LogIn = () => {
    const [formData, setFormData] = useState({
        email: "",
    });
    const [disabled, setDisabled] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<string>("");
    const navigate = useNavigate()

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setError(false);
        setDisabled(false);
        const { name, value } = e.currentTarget;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleNavigation = () => {
        navigate(ROUTES.SIGN_UP);
    };

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    const resetErrors = (): void => {
        setError(false);
        setErrorMessage("");
        setSuccessMessage("");
    };

    const handleSubmit = async (): Promise<any> => {
        resetErrors();
        setDisabled(true);
        if (!formData.email.trim() || !emailRegex.test(formData.email)) {
            setError(true);
            setErrorMessage("Please enter a valid email.");
        } else {
            const emailObj = {
                email: formData.email,
            };
            try {
                const resolve = await logIn(emailObj);
                setSuccessMessage(resolve.message);
                setDisabled(false);
            } catch (error: any) {
                setErrorMessage(error.response?.data.message);
                setError(true)
            }
        }
    };

    return (
        <div className="login-page margin-content">
            <span className="title">{Login.title}</span>
            <span className="subtitle">{Login.subtitle}</span>
            <form className="signup-form">
                <div className="input-div">
                    <label>{Login.emailLabel}</label>
                    <input
                        type="email"
                        name="email"
                        className="signup-page-email-input"
                        placeholder={Login.emailPlaceHolder}
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </div>
                {successMessage && <SuccessMessage message={successMessage} />}
                {error && <div className="error">*{errorMessage}*</div>}
                <button
                    onClick={handleSubmit}
                    className={
                        disabled
                            ? "large-green-button-disabled"
                            : "large-green-button"
                    }
                    disabled={disabled}
                >
                    {Login.button}
                </button>
            </form>
            <span className="nav-link nav-margin" onClick={handleNavigation}>
                {Login.navlink}
            </span>
        </div>
    );
};

export default LogIn