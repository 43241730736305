import React, { useState } from "react";
import "./FixedHeaderUserLayout.css";
import RedirectCheck from "../../routes/RedirectCheck";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../common/Toast/Toast";
import MemberPortalHeader from "./header/MemberPortalHeader";
import { ROUTES } from "../../utils/routes";

interface Props {
  children: React.ReactNode;
  hideMyAccButton?: boolean;
}

const FixedHeaderUserLayout: React.FC<Props> = ({
  children,
}) => {
  const [selectedNavMenu, setSelectedNavMenu] = useState<string | null>(null)
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const navigateToHomePage = () => {
    setSelectedNavMenu(null)
    navigate(ROUTES.HOME)
  }

  return (
    <div className="fixed-header-component fixed-header-layout">
      <RedirectCheck />
      <div className="header">
        <MemberPortalHeader
          handleHomeNavigation={navigateToHomePage}
          selectedNavMenu={selectedNavMenu}
          setSelectedNavMenu={setSelectedNavMenu}
          currentPath={currentPath} /></div>
      <Toast />
      <div className="content">{children}</div>
    </div>
  );
};

export default FixedHeaderUserLayout;
