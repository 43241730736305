import { createContext } from "react";
import { ApiCareSquadCategoryV2 } from "../interfaces/ApiTypes";

interface GlobalState {
  toastMessage: string;
  serviceType: string[] | null;
  loader: boolean;
  menu: boolean;
  categories: ApiCareSquadCategoryV2[] | undefined;
  setServiceType: (serviceType: string[] | null) => void;
  setToastMessage: (error: string) => void;
  globalErrorHandler: (error: any) => void;
  setLoader: (loading: boolean) => void;
  setMenu: (menu: boolean) => void;
  setCategories: (categories: ApiCareSquadCategoryV2[]) => void;
}

const GlobalContext = createContext<GlobalState | undefined>(undefined);

export type { GlobalState };
export default GlobalContext;
