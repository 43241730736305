import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { NavBarLinks } from '../../../utils/member-portal/constant'
import ExpandIcon from '../../assests/icons/expand-menu.png'
import DropdownIcon from '../../assests/icons/expand-icon.png'
import UserAvatar from '../../assests/icons/user-avatar-green.png'
import UserProfileMenu from './UserProfileMenu'
import './ResponsiveNavbar.scss'
import { ApiCareSquadCategory } from '../../../interfaces/ApiTypes'
import { capitalizeFirstLetter } from '../../../utils/helperFunctions'
import { storageKeys } from '../../../utils/constants'
import useGlobalState from '../../../context/useGlobalState'
import { exploreTopicsCategories } from '../../../utils/ApiClient'
import { ROUTES } from '../../../utils/routes'

interface NavbarProps {
  openNavMenu: boolean;
  setOpenNavMenu: (menu: boolean) => void;
  openProfileMenu: boolean;
  setOpenProfileMenu: (menu: boolean) => void;
}

const ResponsiveNavbar: React.FC<NavbarProps> = ({ openNavMenu, setOpenNavMenu, openProfileMenu, setOpenProfileMenu }) => {
  const [openExpolreTopic, setOpenExpolreTopic] = useState<boolean>(false)
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const { globalErrorHandler, setCategories, categories } = useGlobalState()
  const firstName = localStorage.getItem(storageKeys.FirstName)
  const lastName = localStorage.getItem(storageKeys.LastName)

  const fetchCategories = async (): Promise<void> => {
    try {
      const response = await exploreTopicsCategories();
      setCategories(response.data)
    } catch (error) {
      globalErrorHandler(error)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  const handleMenuClick = (index: number) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="response-navbar-component">
      <div onClick={() => setOpenExpolreTopic(!openExpolreTopic)} className='menu-link-buttons'>
        <span>{NavBarLinks.EXPLORE_TOPIC.name}</span>
        <img src={openExpolreTopic ? DropdownIcon : ExpandIcon} alt='expand' />
      </div>
      {
        openExpolreTopic && <> {
          categories?.map((item: ApiCareSquadCategory, index: number) => {
            return (
              <div key={"category" + index}>
                <div key={"menu" + index} className='menu-link-buttons  link-menu-items' onClick={() => handleMenuClick(index)}>
                  <span>{capitalizeFirstLetter(item.label)}</span>
                  <img src={openMenuIndex === index ? DropdownIcon : ExpandIcon} alt='expand' />
                </div>
                {openMenuIndex === index && item.subcategoryData && (
                  <div className="submenu">
                    {item.subcategoryData.map((subItem, subIndex) => (
                      <Link to={ROUTES.TOPIC(subItem.id)} onClick={() => setOpenNavMenu(!openNavMenu)} className='no-underline' key={"submenu" + subIndex}>
                        <div className="submenu-item">
                          {capitalizeFirstLetter(subItem.name)}
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            )
          })
        } </>
      }
      <Link to={ROUTES.INDIVIDUAL_SUPPORT} className="no-underline"><div className='menu-link-buttons'>{NavBarLinks.INDIVIDUAL_SUPPORT.name}</div></Link>
      <Link to={ROUTES.FAQS} className="no-underline"><div className='menu-link-buttons'>{NavBarLinks.FAQS.name}</div></Link>
      <Link to={ROUTES.CONTACT_US} className="no-underline"><div className='menu-link-buttons'>{NavBarLinks.CONTACTUS.name}</div></Link>
      <div className='menu-link-buttons' onClick={() => setOpenProfileMenu(!openProfileMenu)}>
        <div className='user-section-response'>
          <img src={UserAvatar} alt='user-avatar' />
          <span className='user-name'>{firstName} {lastName}</span>
        </div>
        <img src={openProfileMenu ? DropdownIcon : ExpandIcon} alt='expand' />
      </div>
      {
        openProfileMenu && <UserProfileMenu setOpenProfileMenu={setOpenProfileMenu} />
      }
    </div>
  )
}

export default ResponsiveNavbar