import { useEffect, useState } from "react";
import { common } from "../../../../utils/constants";
import { getQuestionsByRequestId } from "../../../../utils/workLifeData";
import { sendWorklifeRequestData } from "../../../../utils/ApiClient";
import Loader from "../../../common/Loader/Loader";
import './RequestForm.scss'
import useGlobalState from "../../../../context/useGlobalState";
import { Stepkeys, WorkLifeAnswersObj } from "../../../../views/worklife-request/WorkLifeRequestSteps";

interface Question {
  text: string;
  id: string;
  type: string;
  options?: string[];
  required?: boolean
}

interface RequestFormProps {
  setCompletionStatus: (key: string, isCompleted: boolean) => void;
  setAnswers: <K extends keyof WorkLifeAnswersObj>(key: K, value: WorkLifeAnswersObj[K]) => void;
  answer: WorkLifeAnswersObj;
}

const RequestForm: React.FC<RequestFormProps> = ({ answer, setCompletionStatus, setAnswers }) => {
  const requestId = answer[Stepkeys.requestOption]?.requestId
  const [questions, setQuestions] = useState<Question[]>([]);
  const [title, setTitle] = useState("");
  const [formAnswers, setFormAnswers] = useState<any>({});
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { globalErrorHandler } = useGlobalState();

  useEffect(() => {
    if (requestId) {
      const response: { requestName: string; questions: Question[] } | null = getQuestionsByRequestId(requestId);
      setTitle(response?.requestName ?? "");
      setQuestions(response?.questions ?? []);
    } else {
      setTitle("");
      setQuestions([]);
    }
  }, [requestId]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    questionKey: string
  ): void => {
    const { type, value } = e.target;
    if (type === "checkbox" && e.target instanceof HTMLInputElement) {
      const { checked } = e.target;
      const currentAnswers = formAnswers[questionKey] || [];
      const updatedAnswers = checked
        ? [...currentAnswers, value]
        : currentAnswers.filter((answer: string) => answer !== value);

      setFormAnswers({
        ...formAnswers,
        [questionKey]: updatedAnswers,
      });
    } else {
      setFormAnswers({
        ...formAnswers,
        [questionKey]: value,
      });
    }
    setError("");
  };

  const validateFields = (): boolean => {
    for (const question of questions) {
      if (question.required && !formAnswers[question.text]) {
        setError(`${question.text} is required.`);
        return false;
      }
    }
    setError("");
    return true;
  };

  const handleClick = async () => {
    if (validateFields()) {
      const requestData = {
        category: answer[Stepkeys.categories]?.categoryName,
        subCategory: answer[Stepkeys.subCategory]?.subCategoryName
          ? answer[Stepkeys.categories]?.categoryName
          : null,
        requestName: title,
        answers: formAnswers,
      };
      try {
        setLoading(true);
        const memberInfo = answer[Stepkeys.worklifeForm];
        const data = {
          member_information: memberInfo,
          work_life_data: requestData
        }
        await sendWorklifeRequestData(data);
        setLoading(false);
        const updatedData = {
          memberInformation: memberInfo,
          workLifeData: requestData
        }
        setCompletionStatus(Stepkeys.requestForm, true);
        setAnswers(Stepkeys.requestForm, updatedData);
      } catch (error) {
        setFormAnswers("");
        setLoading(false);
        globalErrorHandler(error);
      }
    }
  };

  return (
    <div className="request-form-page">
      {loading ? (
        <Loader />
      ) : (
        <div className="page-container">
          <div className="form-container responsive-form">
            <span className="subtitle">{title}</span>
            {questions?.map((question: any) => {
              return (
                <div key={question.id} className="form-grid">
                  <div className="question-label">{question.text}</div>
                  {["text", "number"].includes(question.type) && (
                    <input
                      type={question.type}
                      value={formAnswers[question.text] || ""}
                      onChange={(e) => handleChange(e, question.text)}
                      required
                    />
                  )}
                  {question.type === "radio" && (
                    <div className="radio-group">
                      {question.options?.map(
                        (option: string, index: number) => (
                          <label key={index} className="radio-inline">
                            <input
                              type="radio"
                              name={question.text}
                              value={option}
                              checked={formAnswers[question.text] === option}
                              onChange={(e) => handleChange(e, question.text)}
                              required
                            />
                            {option}
                          </label>
                        )
                      )}
                    </div>
                  )}
                  {question.type === "checkbox" && (
                    <div className="checkbox-group">
                      {question.options?.map((option: string, index: number) => (
                        <label key={index} className="checkbox-inline">
                          <input
                            type="checkbox"
                            name={question.text}
                            value={option}
                            checked={formAnswers[question.text]?.includes(option) || false}
                            onChange={(e) => handleChange(e, question.text)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  )}

                </div>
              );
            })}
          </div>
          {error && <div className="error">{error}</div>}
          <div className='button-box'>
            <button className="green-rounded-btn" onClick={handleClick}>{common.SUBMIT}</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestForm;
