import { selfScheduleQuestion } from "../../../utils/constants";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import Questions from "../../common/SelfScheduleQuestion/SelfScheduleQuestion";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AnswersObj } from "../../../views/support-questionnaire/SupportQuestionnaire";

interface Props {
  onNext: (answers: object) => void;
  answers: AnswersObj;
  stepkey: string;
}

const RadioButtonAssessmentPage2: React.FC<Props> = ({ onNext, answers, stepkey }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
    if (!EnvironmentConfig.show_gad7_phq9) {
      //TODO : NEED to ask about this condition
      navigate("/app/general-questions");
    }
  });
  const option = [
    selfScheduleQuestion.Options.OPTION1,
    selfScheduleQuestion.Options.OPTION2,
    selfScheduleQuestion.Options.OPTION3,
    selfScheduleQuestion.Options.OPTION4,
  ];
  const questions = [
    { question: selfScheduleQuestion.PageTwo.QUESTION_1, options: option },
    { question: selfScheduleQuestion.PageTwo.QUESTION_2, options: option },
    { question: selfScheduleQuestion.PageTwo.QUESTION_3, options: option },
    { question: selfScheduleQuestion.PageTwo.QUESTION_4, options: option },
    { question: selfScheduleQuestion.PageTwo.QUESTION_5, options: option },
    { question: selfScheduleQuestion.PageTwo.QUESTION_6, options: option },
  ];
  const additionalQuestion = selfScheduleQuestion.Additonal_Question.QUESTION2;
  const additionalOptions = selfScheduleQuestion.Additonal_Question.Options;
  return (
    <div>
      <Questions
        option={option}
        questions={questions}
        additionalQuestion={additionalQuestion}
        additionalOptions={additionalOptions}
        onNext={onNext}
        stepkey={stepkey}
        answers={answers}
      />
    </div>
  );
};

export default RadioButtonAssessmentPage2;
