import React from "react";
import FixedHeaderUserLayout from "../../component/layout/FixedHeaderUserLayout";
import Article from "./Article";
import { Navigate, useParams } from "react-router-dom";
import { ROUTES } from "../../utils/routes";

const Index: React.FC = () => {
  const { topicId, articleId } = useParams<{ topicId: string; articleId: string }>();
  const isTopicIdValid = Number(topicId);
  const isArticleIdValid = Number(articleId);

  if (!isTopicIdValid || !isArticleIdValid) {
    return <Navigate to={ROUTES.NOT_FOUND} replace />;
  }
  else
    return (
      <FixedHeaderUserLayout children={<Article />} />
    );
};

export default Index;
