import React, { useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  common,
  questions,
  FormFields,
  EapxParmas,
  storageKeys,
} from "../../../utils/constants";
import { validate } from "../../../utils/validate";
import { sendDataToEAPX, createSendbirdTicket } from "../../../utils/ApiClient";
import CredentialStorage, { UserInfoFormData } from "../../../utils/CredentialStorage";
import { calculateAge, getTicketData } from "../../../utils/helperFunctions";
import useGlobalState from "../../../context/useGlobalState"
import Loader from "../../common/Loader/Loader";
import './UserInformation.scss'
import '../../../styles/formStyles.scss'
import { AnswersObj } from "../../../views/support-questionnaire/SupportQuestionnaire";
import { OPEN_TEXT_COACH_CASE_ERROR } from "../../../utils/member-portal/constant";

interface FormProps {
  onComplete: (answer: object) => void;
  setIsTicketCreated: (isTicketCreated: boolean) => void;
  answers: AnswersObj
}

const UserInformation: React.FC<FormProps> = ({ onComplete, setIsTicketCreated, answers }) => {
  const company: string | null = localStorage.getItem(storageKeys.Company);
  const { globalErrorHandler } = useGlobalState();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormFields>({
    company: company !== null ? company : "",
    member_type: "",
    gender: "",
    marital_status: "",
    phone: "",
    leave_mail: "true",
    street_address: "",
    apt_floor: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initiateEAPXProcess = async (eapxData: UserInfoFormData): Promise<void> => {
    let params: EapxParmas = CredentialStorage.eapxValues(eapxData);
    try {
      await sendDataToEAPX(params);
      getTicket(eapxData);
    } catch (error: any) {
      globalErrorHandler(error);
      setLoading(false);
    }
  };

  const getTicket = async (eapxData: UserInfoFormData): Promise<void> => {
    const ticketData = getTicketData(eapxData);
    const ticketDataToSend = { age: calculateAge(answers.ageGate), ...ticketData }
    try {
      const response = await createSendbirdTicket(ticketDataToSend);
      CredentialStorage.ticketInfo(response);
      setLoading(false);
      setIsTicketCreated(true);
      onComplete(formData)
    } catch (error) {
      globalErrorHandler(error);
      setLoading(false);
    }
  };

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;
    const dropdownName = event.target.name as string;
    setFormData({ ...formData, [dropdownName]: selectedValue });
  };

  const handleSubmit = () => {
    const result = validate(formData);
    if (result.length !== 0) {
      setErrorMessage(result[0]);
      setError(true);
    } else {
      setErrorMessage("");
      setError(false);
      let flow = localStorage.getItem(storageKeys.Flow);
      if (flow === common.SELF_SCHEDULING) {
        const eapxData = { dob: answers.ageGate, age: calculateAge(answers.ageGate), ...formData }
        onComplete(eapxData)
      } else {
        const eapxData = { dob: answers.ageGate, age: calculateAge(answers.ageGate), ...formData }
        let activeTicketCount: any = localStorage.getItem(
          storageKeys.ActiveTicket
        );
        if (activeTicketCount && activeTicketCount > 0) {
          globalErrorHandler(OPEN_TEXT_COACH_CASE_ERROR);
        } else {
          setLoading(true);
          initiateEAPXProcess(eapxData);
        }
      }
    }
  };
  return (
    <div className="user-info-page page-content">
      {loading ? (
        <Loader />
      ) : (
        <>
          <span className="subtitle">{questions.HELP_US_TITLE}</span>
          <form className="form-container responsive-form">
            <div className="form-grid">
              <div className="input-div">
                <label>{common.COMPANY}</label>
                <input
                  name="company"
                  value={formData.company}
                  disabled={company !== null ? true : false}
                  onChange={handleInput}
                />
              </div>
              <div className="input-div">
                <label>{common.MEMBER_TYPE}</label>
                <Select
                  id="member_type"
                  name="member_type"
                  value={formData.member_type}
                  onChange={(
                    event: SelectChangeEvent<string>,
                    child: React.ReactNode
                  ) => handleDropdownChange(event)}
                  className="input-div-select"
                >
                  <MenuItem value={"Employee"}>Employee</MenuItem>
                  <MenuItem value={"Spouse"}>Spouse</MenuItem>
                  <MenuItem value={"Family Member"}>Family Member</MenuItem>
                  <MenuItem value={"Household Member"}>
                    Household Member
                  </MenuItem>
                </Select>
              </div>
              <div className="input-div">
                <label>{common.GENDER}</label>
                <Select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={(
                    event: SelectChangeEvent<string>,
                    child: React.ReactNode
                  ) => handleDropdownChange(event)}
                  className="input-div-select"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </div>
              <div className="input-div">
                <label>{common.MARITAL_STATUS}</label>
                <Select
                  id="marital-status"
                  name="marital_status"
                  value={formData.marital_status}
                  onChange={(
                    event: SelectChangeEvent<string>,
                    child: React.ReactNode
                  ) => handleDropdownChange(event)}
                  className="input-div-select"
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                </Select>
              </div>
              <div className="input-div">
                <label>{common.PHONE}</label>
                <input name="phone" onChange={handleInput} />
              </div>
              <div className="input-div">
                <label>{common.LEAVE_VOICE_MAIL}</label>
                <Select
                  id="leave_mail"
                  name="leave_mail"
                  value={formData.leave_mail}
                  onChange={(
                    event: SelectChangeEvent<string>,
                    child: React.ReactNode
                  ) => handleDropdownChange(event)}
                  className="input-div-select"
                >
                  <MenuItem value={"true"}>Yes</MenuItem>
                  <MenuItem value={"false"}>No</MenuItem>
                </Select>
              </div>
              <div className="input-div">
                <label>{common.STREET_ADDRESS}</label>
                <input name="street_address" onChange={handleInput} />
              </div>
              <div className="input-div">
                <label>{common.APT_FLOOR}</label>
                <input name="apt_floor" onChange={handleInput} />
              </div>
            </div>
            <div className="form-flex">
              <div className="input-div">
                <label>{common.CITY}</label>
                <input name="city" onChange={handleInput} />
              </div>
              <div className="input-div">
                <label>{common.STATE}</label>
                <input name="state" onChange={handleInput} />
              </div>
              <div className="input-div">
                <label>{common.ZIPCODE}</label>
                <input name="zipcode" onChange={handleInput} />
              </div>
            </div>
          </form>
          {error && <div className="error">{errorMessage}</div>}
          <div className="question-button-div">
            <button
              className="green-rounded-btn"
              onClick={handleSubmit}
            >
              {common.NEXT}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default UserInformation;
