import './MemberPortalHeader.scss'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import GreenLogo from '../../assests/icons/logo-green.png'
import CloseIcon from '../../assests/icons/close-black.png'
import MenuIcon from '../../assests/icons/menu-black.png'
import ExpandableMenu from '../../../component/common/ExpandableMenu/ExpandableMenu'
import ResponsiveNavbar from '../../../component/layout/navbar/ResponsiveNavbar'
import NavBar from '../navbar/NavBar'
import { storageKeys } from '../../../utils/constants'
import { ROUTES } from '../../../utils/routes'

interface HeaderProps {
    handleHomeNavigation: () => void;
    currentPath: string;
    selectedNavMenu: string | null;
    setSelectedNavMenu: (menu: string | null) => void;
}

type RoutePath = typeof ROUTES[keyof typeof ROUTES];

const MemberPortalHeader: React.FC<HeaderProps> = ({ handleHomeNavigation, currentPath, selectedNavMenu, setSelectedNavMenu }) => {
    const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false)
    const [openNavMenu, setOpenNavMenu] = useState<boolean>(false)
    const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false)
    const token = localStorage.getItem(storageKeys.Token)
    const navigate = useNavigate()
    const isClientLogoVisible = (currentPath: string): boolean => {
        return Object.values(ROUTES).includes(currentPath as RoutePath);
    };
    const clientLogo: boolean = isClientLogoVisible(currentPath);
    const hiddenPaths = [ROUTES.CALL_PAGE, ROUTES.INVALID_LINK, ROUTES.NOT_FOUND] as const;
    const isValidRoute = (path: string): boolean => {
        return Object.values(ROUTES).includes(path as RoutePath);
    };
    const isNavbarHidden = (currentPath: string): boolean => {
        if (isValidRoute(currentPath)) {
            return hiddenPaths.includes(currentPath as typeof hiddenPaths[number]);
        }
        return false;
    };
    const navbarHidden: boolean = isNavbarHidden(currentPath);

    const handleMenuClick = () => {
        setOpenNavMenu(!openNavMenu)
    }

    return (
        <header className='member-portal-header-component'>
            <div className='left-logo-section'>
                <img
                    src={GreenLogo}
                    alt="logo"
                    onClick={handleHomeNavigation}
                    className='green-logo'
                />
                {
                    !clientLogo && <span className='client-logo'>CLIENT LOGO</span>
                }
            </div>
            {
                !token && !navbarHidden ? <div className="right-alinged-btns">
                    <span className="info-text" onClick={() => navigate(ROUTES.LOGIN)}>SIGN IN</span>
                    <button className="sign-in-btn" onClick={() => navigate(ROUTES.SIGN_UP)}>SIGN UP</button>
                </div> : <>
                    {!navbarHidden && <>
                        <img src={openNavMenu ? CloseIcon : MenuIcon}
                            alt='menu-icon'
                            className='menu-icon'
                            onClick={handleMenuClick} />
                        <div className='nav-bar-response'>
                            <NavBar
                                isMenuExpanded={isMenuExpanded}
                                setIsMenuExpanded={setIsMenuExpanded}
                                selectedNavMenu={selectedNavMenu}
                                setSelectedNavMenu={setSelectedNavMenu}
                                openProfileMenu={openProfileMenu}
                                setOpenProfileMenu={setOpenProfileMenu} />
                        </div>
                    </>
                    }
                </>
            }
            {openNavMenu && token && <ResponsiveNavbar openNavMenu={openNavMenu} setOpenNavMenu={setOpenNavMenu} openProfileMenu={openProfileMenu} setOpenProfileMenu={setOpenProfileMenu} />}
            {isMenuExpanded && !openNavMenu && token && <ExpandableMenu setIsMenuExpanded={setIsMenuExpanded} />}
        </header>
    )
}

export default MemberPortalHeader