import { FC, useState } from "react";
import GlobalContext from "./globalContext";
import { clearLocalStorageExceptOID } from "../utils/clearStorageData";
import { useNavigate, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { storageKeys } from "../utils/constants";
import axios from "axios";
import { ApiCareSquadCategoryV2 } from "../interfaces/ApiTypes";
import { ROUTES } from "../utils/routes";

type GlobalStateProviderProps = {
  children: React.ReactNode;
};

interface GlobalStateProps {
  toastMessage: string;
  serviceType: string[] | null;
  loader: boolean;
  menu: boolean;
  categories: ApiCareSquadCategoryV2[] | undefined;
  setServiceType: (serviceType: string[] | null) => void;
  setToastMessage: (error: string) => void;
  globalErrorHandler: (error: any) => void;
  setLoader: (loading: boolean) => void;
  setMenu: (menu: boolean) => void;
  setCategories: (categories: ApiCareSquadCategoryV2[]) => void;
}

const GlobalState: FC<GlobalStateProviderProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const [toastMessage, setToastMessage] = useState<string>("");
  const [serviceType, setServiceType] = useState<string[] | null>(null);
  const [loader, setLoader] = useState(true);
  const [menu, setMenu] = useState<boolean>(false);
  const [categories, setCategories] = useState<ApiCareSquadCategoryV2[] | undefined>([]);

  const globalErrorHandler = (error: any) => {
    if (error && axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        clearLocalStorageExceptOID(storageKeys.OrganizationID);
        navigate(`${ROUTES.LOGIN}?redirect_url=${pathName}`);
      } else if (error.response?.status === 400) {
        setToastMessage(error.response?.data.message);
      }
      else if (error.response?.status === 404) {
        // On receiving a 404 error response from any API call, trigger navigation to page not found route.
        setToastMessage(error.response?.data.message);
        navigate(ROUTES.NOT_FOUND)
      }
      else {
        setToastMessage(error.message);
        Sentry.captureException(error);
      }
    }
    else {
      setToastMessage(error.message);
      Sentry.captureException(error);
    }
  };

  const globalState: GlobalStateProps = {
    toastMessage,
    serviceType,
    loader,
    menu,
    categories,
    setCategories,
    setServiceType,
    setToastMessage,
    globalErrorHandler,
    setLoader,
    setMenu,
  };

  return (
    <GlobalContext.Provider value={globalState}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
