import { Routes, Route } from "react-router-dom";
import { userType } from "../utils/constants";
import AuthenticatedRoute from "./AuthenticatedRoute";
import VerifyMagicLink from "../component/pages/magic-token-verification/MagicTokenVerification";
import MemberPortalHome from "../views/home/index";
import ExploreTopics from "../views/expore-topics/index";
import Topic from "../views/topic/index";
import IndividualSupport from "../views/individual-support/index";
import Article from "../views/article/index";
import Lesson from "../views/lesson/index";
import Module from "../views/module/index";
import AccountInfo from "../component/pages/account-info/index";
import ChatPage from "../component/pages/chat/index";
import CallPage from "../component/pages/call-page/index";
import InvalidLink from "../component/pages/invalid-link/index";
import Test from "../component/pages/test/index";
import Error from "../component/pages/error/index";
import PageNotFound from "../component/pages/page-not-found/index";
import PrivacyPolicy from "../component/pages/privacy-policy/index";
import ServicesCheck from "../component/pages/service-check/index";
import TermsOfService from "../component/pages/terms-of-service";
import SplashScreen from "../views/splash-screen/index";
import SignUp from "../views/sign-up/index";
import Login from "../views/login/index";
import FAQs from "../views/faq/index";
import ContactUs from "../views/contact-us/index";
import SupportQuestionnaire from "../views/support-questionnaire/index";
import WorkLifeRequestSteps from "../views/worklife-request/index";
import { ROUTES } from "../utils/routes";

const AppRouter = () => {
  return (
    <Routes>
      {/* New Routes */}
      <Route path={ROUTES.HOMEPAGE} element={<SplashScreen />} />
      <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route
        path={ROUTES.VERIFY_MAGIC_LINK()}
        element={<VerifyMagicLink />}
      />

      <Route
        path="/app/*"
        element={<AuthenticatedRoute allowedType={userType.CUSTOMER} />}
      >
        <Route path={ROUTES.HOME_DEF} element={<MemberPortalHome />} />
        <Route path={ROUTES.EXPLORE_TOPICS_DEF} element={<ExploreTopics />} />
        <Route path={ROUTES.TOPIC_DEF} element={<Topic />} />
        <Route path={ROUTES.MODULE_DEF} element={<Module />} />
        <Route path={ROUTES.LESSON_DEF} element={<Lesson />} />
        <Route path={ROUTES.ARTICLE_DEF} element={<Article />} />
        <Route path={ROUTES.INDIVIDUAL_SUPPORT_DEF} element={<IndividualSupport />} />

        <Route path={ROUTES.SUPPORT_QUESTIONNAIRE_DEF} element={<SupportQuestionnaire />} />

        {/* TEXT-COACHING & SELF-SCHEDULING-FLOW COMMON ROUTES */}
        <Route path={ROUTES.ACCOUNT_INFO_DEF} element={<AccountInfo />} />

        {/* TEXT-COACHING-FLOW ROUTES */}
        <Route path={ROUTES.CHAT_DEF} element={<ChatPage />} />

        {/* WORK-LIFE-REQUEST-FLOW ROUTES  */}
        <Route path={ROUTES.WORKLIFE_REQUEST_DEF} element={<WorkLifeRequestSteps />} />

        <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route path={ROUTES.FAQS} element={<FAQs />} />
      <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />

      {/* GENERAL ROUTES */}
      <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={ROUTES.TERMS_OF_SERVICE} element={<TermsOfService />} />
      <Route path={ROUTES.CALL_PAGE} element={<CallPage />} />
      <Route path={ROUTES.INVALID_LINK} element={<InvalidLink />} />
      <Route path={ROUTES.SYSTEM_CHECK} element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<ServicesCheck />} />} />
      <Route path={ROUTES.TEST} element={<Test />} />
      <Route path={ROUTES.ERROR} element={<Error />} />
      <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />

    </Routes>
  );
};

export default AppRouter;
