import React, { useState, useEffect } from 'react'
import './QuestionnaireQues.scss'
import DropDownMenu from '../Input/DropDownMenu'
import SmallGreenButton from '../Button/SmallGreenButton'
import { ApiQuestions, ApiLessonScore } from '../../../interfaces/ApiTypes'
import { AnswerType } from '../JournalEntry/JournalEntry'
import { validateAnswers } from '../../../utils/helperFunctions'
import { getLessonScore, submitExerciseAnswers, updateLessonProgress } from '../../../utils/ApiClient'
import SuccessMessage from '../SuccessMessage/SuccessMessage'
import useGlobalState from '../../../context/useGlobalState'
import LoadingButton from '../Button/LoadingButton'
import QuestionnaireScore from './QuestionnaireScore'
import TitleDescription from '../TitleDescription/TitleDescription'

interface QuestionProps {
    question: ApiQuestions[];
    id: number;
    isCompleted: boolean;
    title: string;
    description: string;
    onNextButtonClick: () => void;
}

const QuestionnaireQues: React.FC<QuestionProps> = ({ question, id, isCompleted, title, description, onNextButtonClick }) => {
    const [questions, setQuestions] = useState<ApiQuestions[]>(question);
    const [answers, setAnswers] = useState<AnswerType[]>([]);
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [successMessage, setSuccessMessage] = useState<string | null>("")
    const [loading, setLoading] = useState<boolean>(false)
    const { globalErrorHandler } = useGlobalState()
    const [scoreObj, setScoreObj] = useState<ApiLessonScore>({} as ApiLessonScore)
    const [lessonCompleted, setLessonCompleted] = useState<boolean>()

    useEffect(() => {
        setQuestions(question)
    }, [question])

    useEffect(() => {
        if (isCompleted) {
            setLessonCompleted(true)
            fetchLessonScore()
        }
    }, [isCompleted])

    const fetchLessonScore = async (): Promise<void> => {
        try {
            const response = await getLessonScore(id)
            setScoreObj(response.data)
            setLessonCompleted(true)
        } catch (error) {
            globalErrorHandler(error)
        }
    }

    const handleInputChange = (id: number, value: string, isRequired: boolean, question: string) => {
        setAnswers((prevAnswers) => {
            const existingAnswerIndex = prevAnswers.findIndex((answer) => answer.question_id === id);
            if (existingAnswerIndex !== -1) {
                return prevAnswers.map((answer, index) =>
                    index === existingAnswerIndex ? { ...answer, answer: value } : answer
                );
            }
            return [...prevAnswers, { question_id: id, answer: value, isRequired: isRequired, question: question }];
        });
    };

    const handleSubmitAnswers = async (): Promise<void> => {
        const cleanedData = answers.map(({ question, isRequired, ...rest }) => rest);
        const hasError = validateAnswers(answers)
        if (!hasError) {
            setError(false)
            setLoading(true)
            try {
                const response = await submitExerciseAnswers(id, cleanedData)
                setTimeout(async () => {
                    setLoading(false)
                    setSuccessMessage(response.message)
                    await updateLessonProgress(id, true)
                    onNextButtonClick()
                    fetchLessonScore()
                }, 3000)
            } catch (error) {
                globalErrorHandler(error)
            }
        } else {
            setErrorMessage(hasError)
            setError(true)
        }
    }

    return (
        <div className='questionnaire-ques-component'>
            {/* The Next button appears only when the lesson is not completed, regardless of whether it is the last lesson or not. */}

            {!lessonCompleted &&
                <TitleDescription
                    title={title}
                    description={description}
                    onNextButtonClick={onNextButtonClick} />
            }
            {
                lessonCompleted ? <QuestionnaireScore scoreObj={scoreObj} />
                    : <>
                        {
                            questions.map((quest, index) => {
                                return <DropDownMenu
                                    question={quest.question}
                                    id={quest.id}
                                    isRequired={quest.isRequired}
                                    handleInputChange={handleInputChange}
                                    value={answers.find((answer) => answer.question_id === quest.id)?.answer || ''}
                                    options={quest.options}
                                    key={index + "que"} />
                            })
                        }
                    </>
            }
            {
                error && errorMessage && <span className='error'>{errorMessage}</span>
            }
            {
                successMessage && <SuccessMessage message={successMessage} />
            }
            {
                loading ? <LoadingButton /> : <>{!lessonCompleted && <SmallGreenButton name='Submit' onClick={handleSubmitAnswers} />}
                </>
            }
        </div>
    )
}

export default QuestionnaireQues 