import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { accountInfo, storageKeys } from "../../../utils/constants";
import {
  ticketList,
  getServiceType,
} from "../../../utils/ApiClient";
import Loader from "../../common/Loader/Loader";
import ServiceCards from "../../common/ServiceComponent/ServiceComponent";
import { useNavigationHandler } from "../../../utils/useNavigationHandler";
import ConversationList from "../../ConversationList/ConversationList";
import "./AccountInfo.scss";
import "../../../styles/globalStyles.scss";
import useGlobalState from "../../../context/useGlobalState";
import { ApiTicket } from "../../../interfaces/ApiTypes";
import { ROUTES } from "../../../utils/routes";
import { INTERNAL_APPLICATION_ERROR } from "../../../utils/member-portal/constant";

const AccountInfo: React.FC = () => {
  const navigate = useNavigate();
  const { changedFlowNavigation } = useNavigationHandler();
  const [activeTickets, setActiveTickets] = useState<number>(0);
  const [serviceType, setServiceType] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tickets, setTickets] = useState<ApiTicket[]>([]);
  const [serviceCompleted, setServiceCompleted] = useState(false);
  const { globalErrorHandler, toastMessage } = useGlobalState()
  const oId: string | null = localStorage.getItem(storageKeys.OrganizationID);

  const getService = async (): Promise<void> => {
    try {
      if (oId) {
        const response = await getServiceType(oId);
        const responseArray = response.data.listOfAvailableService;
        setServiceType(responseArray);
        localStorage.setItem(
          storageKeys.AvailableService,
          JSON.stringify(responseArray)
        );
        setServiceCompleted(true);
      } else {
        setLoading(false);
        globalErrorHandler(INTERNAL_APPLICATION_ERROR)
      }
    } catch (error) {
      setLoading(false);
      globalErrorHandler(error);
    }
  };

  const getTickets = async (): Promise<void> => {
    setLoading(true)
    try {
      const ticketsResponse = await ticketList();
      setActiveTickets(ticketsResponse.data.activeTicketCount);
      localStorage.setItem(
        storageKeys.ActiveTicket,
        ticketsResponse.data.activeTicketCount.toString()
      );
      setTickets(ticketsResponse.data.tickets);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      globalErrorHandler(error);
    }
  };
  useEffect(() => {
    getService();
  }, []);

  useEffect(() => {
    getTickets();
  }, [serviceCompleted]);

  const handleButton = async (flow?: string) => {
    const flowName = flow || "";
    changedFlowNavigation(flowName);
  };

  const renderNoConversation = useMemo(
    () =>
      !tickets.length &&
      !loading && (
        <span className="no-conversation">{accountInfo.NO_CONVERSATIONS}</span>
      ),
    [tickets.length, loading]
  );

  const handleticket = (ticketId: number) => {
    navigate(ROUTES.CHAT(ticketId));
  };

  return (
    <div
      className="account-info-page"
      style={toastMessage !== null ? { marginTop: "10px" } : {}}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="grid-div">
          <div className="account-info-content page-container">
            <span className="title">{accountInfo.TITLE}</span>
            {renderNoConversation}
            <ConversationList tickets={tickets} handleticket={handleticket} />
            <div className="service-cards-div">
              <ServiceCards
                serviceType={serviceType}
                handleButtons={handleButton}
                activeTickets={activeTickets}
                isLandingPage={false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountInfo;
