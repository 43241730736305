import React from "react";
import FixedHeaderUserLayout from "../../component/layout/FixedHeaderUserLayout";
import Lesson from "./Lesson";
import { Navigate, useParams } from "react-router-dom";
import { ROUTES } from "../../utils/routes";

const Index: React.FC = () => {
  const { moduleId, lessonId } = useParams<{ moduleId?: string; lessonId?: string }>();
  const isModuleIdValid = Number(moduleId);
  const isLessonIdValid = Number(lessonId);

  if (!isModuleIdValid || !isLessonIdValid) {
    return <Navigate to={ROUTES.NOT_FOUND} replace />;
  }
  else
    return (
      <FixedHeaderUserLayout children={<Lesson />} />
    );
};

export default Index;
