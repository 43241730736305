import { NavLink, useLocation } from 'react-router-dom';
import { NavBarLinks } from '../../../utils/member-portal/constant'
import ExpandIcon from '../../assests/icons/small-expand-icon.png'
import ExpandMenu from '../../assests/icons/expand-menu.png'
import CloseMenuIcon from '../../assests/icons/close-menu.png'
import UserAvatar from '../../assests/icons/user-avatar-green.png'
import UserProfileMenu from './UserProfileMenu'
import './NavBar.scss'
import { storageKeys } from '../../../utils/constants';
import React, { useEffect } from 'react';
import useGlobalState from '../../../context/useGlobalState';
import { exploreTopicsCategories } from '../../../utils/ApiClient';

interface NavbarProps {
    isMenuExpanded: boolean;
    setIsMenuExpanded: (expand: boolean) => void;
    selectedNavMenu: string | null;
    setSelectedNavMenu: (menu: string | null) => void;
    openProfileMenu: boolean;
    setOpenProfileMenu: (menu: boolean) => void;
}

const NavBar: React.FC<NavbarProps> = ({ isMenuExpanded, setIsMenuExpanded, selectedNavMenu, setSelectedNavMenu, openProfileMenu, setOpenProfileMenu }) => {
    const location = useLocation();
    const firstName = localStorage.getItem(storageKeys.FirstName)
    const lastName = localStorage.getItem(storageKeys.LastName)
    const { globalErrorHandler, setCategories, categories } = useGlobalState()

    const fetchCategories = async (): Promise<void> => {
        try {
            const response = await exploreTopicsCategories();
            setCategories(response.data)
        } catch (error) {
            globalErrorHandler(error)
        }
    }

    useEffect(() => {
        if (!categories?.length) {
            fetchCategories()
        }
    }, [])

    const handleMouseEnter = (name: string) => {
        if (name !== NavBarLinks.EXPLORE_TOPIC.name) {
            if (isMenuExpanded) {
                setIsMenuExpanded(false)
            }
            return
        }
        setIsMenuExpanded(true)
    }

    const onMenuClick = (name: string) => {
        setIsMenuExpanded(false)
        setSelectedNavMenu(name)
    }

    return (
        <nav className='navbar-component'>
            {Object.entries(NavBarLinks).map(([key, value]) => {
                return (
                    <NavLink to={value.path} className="no-underline" key={key}>
                        <div className={selectedNavMenu === value.name || location.pathname === value.path ? 'link-buttons selected-menu' : 'link-buttons'}
                            onClick={() => onMenuClick(value.name)}
                            onMouseEnter={() => handleMouseEnter(value.name)}>
                            <span>{value.name}</span>
                            {value.name === NavBarLinks.EXPLORE_TOPIC.name && <img alt="menu-icon" src={ExpandMenu} className='menu-icon' />}
                        </div>
                    </NavLink>
                )
            })}
            <div className='logged-in-user' onClick={() => setOpenProfileMenu(!openProfileMenu)}>
                <img src={UserAvatar} alt='user-avatar' className='user-avatar' />
                <span className='user-name'>{firstName} {lastName}</span>
                <img src={openProfileMenu ? CloseMenuIcon : ExpandIcon} alt='expand' />
            </div>
            {
                openProfileMenu && <UserProfileMenu setOpenProfileMenu={setOpenProfileMenu} />
            }
        </nav>
    )
}

export default NavBar