import React from 'react'
import './LessonsList.scss'
import CheckedIcon from '../../assests/icons/green-checked.png'
import { ApiLesson } from '../../../interfaces/ApiTypes'

interface ListItemProps {
    item: ApiLesson;
    currentLessonId?: number | null | undefined;
    onLessonClick?: (lessonId: ApiLesson) => void;
}

const LessonsList: React.FC<ListItemProps> = ({ item, onLessonClick, currentLessonId }) => {

    return (
        <div className={`lessons-list-component ${currentLessonId === item.id && "active-module"}`} onClick={() => onLessonClick?.(item)}>
            {
                item.isCompleted ?
                    <img src={CheckedIcon} alt='icon' className='checked-icon-green' />
                    :
                    <img src={item.icon} alt='icon' className='checked-icon-green' />
            }
            <div className='course-details'>
                <span className='course-item-title'>
                    {item.title}
                </span>
            </div>
        </div>
    )
}

export default LessonsList