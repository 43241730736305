import React from 'react'
import './ModuleSidebar.scss'
import ModulSections from './module-sections/ModulSections'
import { ApiLesson, Sections } from '../../../../interfaces/ApiTypes'
import BackToModule from '../../../common/BackToModule/BackToModule'
import { ROUTES } from '../../../../utils/routes'

interface SidebarProps {
    id: number;
    sectionId: number | null | undefined;
    setSectionId: (id: number | null) => void;
    currentLessonId: number | null | undefined;
    onSidebarLessonClick: (lessonId: ApiLesson) => void;
    cbtModuleSections: Sections[]
}

const ModuleSidebar: React.FC<SidebarProps> = ({ id, sectionId, setSectionId, currentLessonId, onSidebarLessonClick, cbtModuleSections }) => {
    return (
        <div className='module-sidebar-component'>
            <BackToModule name='Back to module' path={ROUTES.MODULE(id)} />
            <div className='modules-list'>
                {
                    cbtModuleSections.map((item: Sections, index: number) => {
                        return <ModulSections
                            list={item}
                            key={'module' + index}
                            setSectionId={setSectionId}
                            currentLessonId={currentLessonId}
                            onLessonClick={onSidebarLessonClick}
                            sectionId={sectionId ?? null} />
                    })
                }
            </div>
        </div >
    )
}

export default React.memo(ModuleSidebar)