import './HeroGridImages.scss'
import EnvironmentConfig from '../component/config/EnvironmentConfig'
import { Link } from 'react-router-dom'
import { ROUTES } from '../utils/routes'

const HeroGridImages = () => {
  return (
    <div className='hero-grid-component'>
      <Link to={ROUTES.EXPLORE_TOPICS} className='no-underline'><img src={`${EnvironmentConfig.imageBaseURL}explore-topic.png`} alt="explore-topic-image" className='grid-image' /></Link>
      <Link to={ROUTES.INDIVIDUAL_SUPPORT} className='no-underline'><img src={`${EnvironmentConfig.imageBaseURL}access-support.png`} alt="access-support-image" className='grid-image' /></Link>
      <img src={`${EnvironmentConfig.imageBaseURL}learn-abt-cca.png`} alt="learn-abt-cca-large-image" className='grid-image response-hide' />
      <img src={`${EnvironmentConfig.imageBaseURL}leran-abt-small.png`} alt="learn-abt-cca-large-image" className='grid-image-response' />
    </div>
  )
}

export default HeroGridImages