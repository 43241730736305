import { Navigate } from "react-router-dom";
import FixedHeaderUserLayout from "../../component/layout/FixedHeaderUserLayout";
import { storageKeys, userType } from "../../utils/constants";
import SplashScreen from "./SplashScreen";
import { ROUTES } from "../../utils/routes";

const Index = () => {
    const token: string | null = localStorage.getItem(storageKeys.Token);
    const userTypeName = localStorage.getItem(storageKeys.UserType)
    if (token && userTypeName === userType.CUSTOMER) {
        return <Navigate to={ROUTES.HOME} />;
    }
    return (
        <FixedHeaderUserLayout children={<SplashScreen />} />
    );
};

export default Index;
