import ContentDescriptionCard from '../../component/common/Cards/ContentDescriptionCard'
import './Module.scss'
import ModuleSection from '../../component/common/ModuleSection/ModuleSection'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getCBTModule, updateModuleProgress } from '../../utils/ApiClient'
import { ApiCBTModule } from '../../interfaces/ApiTypes'
import Loader from '../../component/common/Loader/Loader'
import useGlobalState from '../../context/useGlobalState'
import { INTERNAL_APPLICATION_ERROR, ModuleStatus } from '../../utils/member-portal/constant'
import { findFirstIncompleteLesson } from '../../utils/helperFunctions'
import { ROUTES } from '../../utils/routes'

const Module = () => {
  const { moduleId } = useParams<{ moduleId: string }>();

  const [loading, setLoading] = useState<boolean>(true)
  const [moduleDetails, setModuleDetails] = useState<ApiCBTModule | null>(null)
  const firstIncompleteLesson = findFirstIncompleteLesson(moduleDetails?.sections ?? []);
  const lessonID = firstIncompleteLesson ? firstIncompleteLesson.lessonId : null;
  const { globalErrorHandler } = useGlobalState()
  const navigate = useNavigate()

  const fetchCBTModules = async (): Promise<void> => {
    try {
      const response = await getCBTModule(Number(moduleId))
      setModuleDetails(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      globalErrorHandler(error)
    }
  }

  useEffect(() => {
    fetchCBTModules()
  }, [moduleId])

  const handleModuleProgress = async (): Promise<void> => {
    if (moduleId) {
      try {
        await updateModuleProgress(Number(moduleId), ModuleStatus.inProgress)
        if (moduleDetails && moduleDetails.sections) {
          const lessonId = findFirstIncompleteLesson(moduleDetails.sections)
          if (lessonId) {
            navigate(ROUTES.LESSON(Number(moduleId), lessonId.lessonId))
          } else {
            globalErrorHandler(INTERNAL_APPLICATION_ERROR)
          }
        }
      } catch (error) {
        globalErrorHandler(error)
      }
    }
    else {
      globalErrorHandler(INTERNAL_APPLICATION_ERROR)
    }
  }

  return (
    <>
      {
        loading ? <Loader /> :
          <>{
            moduleDetails && moduleDetails.sections && <div className='module-page'>
              <img src={moduleDetails.thumbnail} alt="module-cover-img" className='module-cover-image' />
              <ContentDescriptionCard
                id={moduleDetails.id}
                disableButton={!findFirstIncompleteLesson(moduleDetails.sections)?.lessonId}
                path={ROUTES.LESSON(moduleDetails.id, lessonID)}
                title={moduleDetails.name}
                content={moduleDetails.description}
                isStarted={moduleDetails.isStarted}
                exercise={moduleDetails.totalExercises}
                videos={moduleDetails.totalVideos}
                totalMins={moduleDetails.totalMinutes}
                status={moduleDetails.status}
                remainingMins={moduleDetails.remainingMintues}
                drDetails={moduleDetails.curatorName}
                startButtonClick={handleModuleProgress} />
              <div className='container'>
                <ModuleSection
                  moduleSectionList={moduleDetails.sections}
                  moduleId={moduleDetails.id}
                  isStarted={moduleDetails.isStarted}
                  disableResumeButton={findFirstIncompleteLesson(moduleDetails.sections)?.lessonId ? false : true}
                  startButtonClick={handleModuleProgress} />
              </div>
            </div>
          }</>
      }
    </>
  )
}

export default Module