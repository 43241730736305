import axios from "axios";
import { AxiosRequestConfig } from "axios";

import EnvironmentConfig from "../component/config/EnvironmentConfig";
import { storageKeys } from "./constants";
import { DataObject } from "../interfaces/ApiTypes";
import { 
  ApiResponse, 
  PartialApiResponse,
  LoginResponse, 
  ApiTicket, 
  ApiTicketsList,
  ApiOrganizationServices,
  ApiTicketV2,
  ApiArticle,
  ApiCBTModule,
  Sections,
  ApiLesson,
  ScheduleData,
  CompleteSubCategoryData,
  ApiLessonScore,
  SessionToken,
  EAPXOrganization,
  ApiCareSquadCategoryV2
} from "../interfaces/ApiTypes";

export const headers = {
  "Content-Type": "application/json",
};

export const authHeaders = () => {
  const headers: AxiosRequestConfig["headers"] = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem(storageKeys.Token) || null,
  };
  return headers;
};

//Api for Registration
export const register = (data: DataObject): Promise<ApiResponse<LoginResponse>> => {
  return axios.post(`${EnvironmentConfig.ccaApiRoot}/signup/`, data, {
    headers: headers,
  }).then((response) => response.data)
};

//Api for Login
export const logIn = (data: any): Promise<PartialApiResponse> => {
  return axios.post(`${EnvironmentConfig.ccaApiRoot}/login/`, data, {
    headers: headers,
  }).then((response) => response.data)
};

//Api for verify user through magic-link
export const userVerification = (user_id: any, magic_token: any): Promise<ApiResponse<LoginResponse>> => {
  return axios.get(
    `${EnvironmentConfig.ccaApiRoot}/verify/${user_id}/${magic_token}`
  ).then((response) => response.data)
};

//Api for get EAPX organization detail using oId
export const getOrganizationDetail = (oId: string): Promise<ApiResponse<EAPXOrganization>> => {
  return axios.get(
    `${EnvironmentConfig.ccaApiRoot}/eapx/organization?oid=${oId}`,
    {
      headers: headers,
    }
  ).then((response) => response.data)
};

//Api for Logout
export const logout = async (): Promise<ApiResponse<void>> => {
  return axios.post(
    `${EnvironmentConfig.ccaApiRoot}/logout/`,
    {},
    {
      headers: authHeaders(),
    }
  ).then((response) => response.data)
};

//Api for Ticket-list
export const ticketList = (): Promise<ApiResponse<ApiTicketsList>> => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/tickets/list`, {
    headers: authHeaders(),
  }).then((response) => response.data)
};

//Api for send data to eapx
export const sendDataToEAPX = (data: DataObject): Promise<ApiResponse<void>> => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/eapx`, {
    params: data,
    headers: authHeaders(),
  }).then((response) => response.data)
};

//Api for create-ticket
export const createSendbirdTicket = (data: DataObject): Promise<ApiResponse<ApiTicketV2>>  => {
  return axios.post(`${EnvironmentConfig.ccaApiRoot}/sendbird/create-ticket/`, data, {
    headers: authHeaders(),
  }).then((response) => response.data)
};

//TODO: write response
export const getSessionToken = (): Promise<ApiResponse<SessionToken>> => {
  return axios.post(
    `${EnvironmentConfig.ccaApiRoot}/generate/session-token`,
    {},
    { headers: authHeaders() }).then((response) => response.data)
}

//Api for get organization detail using user
export const getUserOrganizationDetail = () => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/organization/detail`, {
    headers: authHeaders(),
  }).then((response) => response.data)
};

// API to get ticket info
export const getTicketInfo = (ticketId: string): Promise<ApiResponse<ApiTicket>>  => {
  return axios.get(
    `${EnvironmentConfig.ccaApiRoot}/ticket/get?ticket_id=${ticketId}`,
    {
      headers: authHeaders(),
    }
  ).then((response) => response.data)
};

//Api to get control which services are available
export const getServiceType = (oId: string): Promise<ApiResponse<ApiOrganizationServices>> => {
  return axios.get(
    `${EnvironmentConfig.ccaApiRoot}/eapx/organization/services?oid=${oId}`
  ).then((response) => response.data)
};

//Api to test email delivery
export const emailCheck = (email: string): Promise<PartialApiResponse> => {
  return axios.post(
    `${EnvironmentConfig.ccaApiRoot}/email-check`,
    {
      user_email: email,
      support_email: EnvironmentConfig.supportEmail,
    },
    {
      headers: headers,
    }
  ).then((response) => response.data)
};

//Api to test service is working or not via email
export const serviceCheck = (): Promise<PartialApiResponse> => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/sendbird-check`, {
    headers: headers,
  }).then((response) => response.data)
};

//Api to send request-data for work-life request flow
export const sendWorklifeRequestData = (requestData: DataObject): Promise<PartialApiResponse> => {
  return axios.post(
    `${EnvironmentConfig.ccaApiRoot}/worklife-request/data/`,
    requestData,
    {
      headers: authHeaders(),
    }
  ).then((response) => response.data)
};

//Api to get Explore topic's categories
export const exploreTopicsCategories = (): Promise<ApiResponse<ApiCareSquadCategoryV2[]>> => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/content-categories/`,
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}

//Api to get get-topic-Details
export const getTopicDetails = (id: number | null): Promise<ApiResponse<CompleteSubCategoryData>> => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/category-data/module/${id}/`,
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}

//Api to get get topic digital-content
export const getTopicArticleList = (id: number | null) => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/content-category/${id}/`,
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}

export const getCBTModule = (id: number | null): Promise<ApiResponse<ApiCBTModule>> => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/cbt-module/${id}/`,
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}

export const getCBTSectionLessons = (id: number | null): Promise<ApiResponse<Sections[]>> => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/cbt-section/${id}/lesson`,
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}

export const getLessonDetails = (id: number | null): Promise<ApiResponse<ApiLesson>> => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/lesson/${id}/`,
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}

export const submitExerciseAnswers = (lessonId: number, exerciseData: DataObject): Promise<PartialApiResponse> => {
  return axios.post(`${EnvironmentConfig.ccaApiRoot}/submit/exercise-answer/`, {
    lesson_id: lessonId,
    exercise_data: exerciseData
  },
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}

export const getExerciseData = (lessonId:number): Promise<ApiResponse<ScheduleData>> => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/lesson/${lessonId}/table-data/`, {
    headers: authHeaders(),
  }).then((response) => response.data)
};


export const editJournalAnswer = (lessonId: number, submission_id: number, exerciseData: DataObject): Promise<PartialApiResponse> => {
  return axios.patch(`${EnvironmentConfig.ccaApiRoot}/edit/exercise-answer/`, {
    lesson_id: lessonId,
    submission_id: submission_id,
    exercise_data: exerciseData
  },
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}

export const deleteJournalAnswer = (journalId: number): Promise<PartialApiResponse> => {
  return axios.delete(`${EnvironmentConfig.ccaApiRoot}/delete/${journalId}/exercise-answer/`,
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}

export const getArticleDetail = (id: number): Promise<ApiResponse<ApiArticle>> => {

  return axios.get(`${EnvironmentConfig.ccaApiRoot}/article/${id}`, {
    headers: authHeaders(),
  }).then((response) => response.data)
}

export const updateModuleProgress = (moduleId: number | null, status: string): Promise<PartialApiResponse> => {
  return axios.post(`${EnvironmentConfig.ccaApiRoot}/update-module-progress/`, {
    module: moduleId,
    status: status
  },
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}

export const updateLessonProgress = (lessonId: number, status: boolean): Promise<PartialApiResponse> => {
  return axios.post(`${EnvironmentConfig.ccaApiRoot}/update-lesson-progress/`, {
    lesson: lessonId,
    is_completed: status
  },
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}

export const getLessonScore = (lessonId: number): Promise<ApiResponse<ApiLessonScore>> => {
  return axios.get(`${EnvironmentConfig.ccaApiRoot}/lesson/${lessonId}/score/`,
    {
      headers: authHeaders(),
    }).then((response) => response.data)
}
