import './Lesson.scss'
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import LessonAndSidebar from '../../component/common/LessonAndSidebar/LessonAndSidebar'
import { useCallback, useEffect, useState } from 'react';
import { ApiLesson, Sections } from '../../interfaces/ApiTypes';
import useGlobalState from '../../context/useGlobalState';
import { getCBTSectionLessons, getLessonDetails, updateLessonProgress } from '../../utils/ApiClient';
import { findSectionObjectById, getNextLesson } from '../../utils/helperFunctions';
import { INTERNAL_APPLICATION_ERROR } from '../../utils/member-portal/constant';
import { ROUTES } from '../../utils/routes';

const Lesson = () => {
    const { moduleId, lessonId } = useParams();
    const [cbtModuleSections, setcbtModuleSections] = useState<Sections[]>([]);
    const [sectionId, setSectionId] = useState<number | null>(null);
    const [selectedLessonId, setSelectedLessonId] = useState<number | null>(null);
    const [lessonDetails, setLessonDetails] = useState<ApiLesson | null>(null)
    const [isLessonFetching, setIsLessonFetching] = useState<boolean>(false)
    const [isSectionLoading, setIsSectionLoading] = useState<boolean>(true)
    const { globalErrorHandler } = useGlobalState();
    const navigate = useNavigate()
    const location = useLocation();

    const fetchLesson = useCallback(async (cbtModuleSections: Sections[], selectedLessonId: number): Promise<void> => {
        try {
            setIsLessonFetching(true)
            const response = await getLessonDetails(selectedLessonId)
            const result = findSectionObjectById(cbtModuleSections, selectedLessonId);
            if (result) {
                setSectionId(result.lessonGroup.id);
                setSelectedLessonId(selectedLessonId)
                setLessonDetails(response.data)
                setIsLessonFetching(false)
            } else {
                globalErrorHandler(INTERNAL_APPLICATION_ERROR);
            }
        } catch (error) {
            setIsLessonFetching(false)
            globalErrorHandler(error)
        }
    }, [lessonId])

    const handleLessonClick = useCallback((item: ApiLesson) => {
        const isModulePage = /^\/app\/module\/\d+$/.test(location.pathname);
        if (isModulePage) return;
        navigate(ROUTES.LESSON(Number(moduleId), item.id))
    }, [lessonId])

    const handleNextButton = async (lesson: ApiLesson, status: boolean): Promise<void> => {
        try {
            await updateLessonProgress(lesson.id, status);
            if (lessonDetails) {
                const nextLesson = getNextLesson(cbtModuleSections, lessonDetails.id);
                if (nextLesson) {
                    navigate(ROUTES.LESSON(Number(moduleId), nextLesson.lessonId))
                }
            } else {
                globalErrorHandler(INTERNAL_APPLICATION_ERROR);
            }
        } catch (error) {
            globalErrorHandler(error);
        }
    }

    useEffect(() => {
        // Combined API calls to keep module-section data up-to-date and avoid local state updates for module-section.
        const fetchSections = async (): Promise<void> => {
            try {
                const response = await getCBTSectionLessons(Number(moduleId));
                setcbtModuleSections(response.data);
                setIsSectionLoading(false);
                if (lessonId) {
                    fetchLesson(response.data, Number(lessonId))
                } else {
                    globalErrorHandler(INTERNAL_APPLICATION_ERROR);
                }
            } catch (error) {
                setIsSectionLoading(false);
                globalErrorHandler(error)
            }
        };
        fetchSections();
    }, [moduleId, lessonId]);

    return (
        <div className='lesson-page'>
            <LessonAndSidebar
                moduleId={Number(moduleId)}
                isSectionLoading={isSectionLoading}
                cbtModuleSections={cbtModuleSections}
                sectionId={sectionId}
                setSectionId={setSectionId}
                selectedLessonId={selectedLessonId}
                setSelectedLessonId={setSelectedLessonId}
                lessonDetails={lessonDetails}
                isLessonFetching={isLessonFetching}
                onLessonUpdate={() => fetchLesson(cbtModuleSections, Number(lessonId))}
                onNextButtonClick={handleNextButton}
                onSidebarLessonClick={handleLessonClick} />
        </div>
    )
}

export default Lesson