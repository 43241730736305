export const ROUTES = {
    HOMEPAGE: "/",
    SIGN_UP: "/sign-up",
    LOGIN: "/login",
    VERIFY_MAGIC_LINK: (user_id = ":user_id", magic_token = ":magic_token") =>
        `/verify/${user_id}/${magic_token}`,

    // This route is relative to a nested <Route> inside <Route path="app/*">.  
    // The parent route already includes "app/", so it's not needed here.
    HOME_DEF: "home",
    HOME: "/app/home",
    EXPLORE_TOPICS_DEF: "explore-topics",
    EXPLORE_TOPICS: "/app/explore-topics",
    TOPIC_DEF: "topic/:topicId",
    TOPIC: (topicId: number) => `/app/topic/${topicId}`,
    MODULE_DEF: "module/:moduleId",
    MODULE: (moduleId: number) => `/app/module/${moduleId}`,
    LESSON_DEF: "module/:moduleId/lesson/:lessonId",
    LESSON: (moduleId: number, lessonId: number | null) => `/app/module/${moduleId}/lesson/${lessonId}`,
    ARTICLE_DEF: "topic/:topicId/article/:articleId",
    ARTICLE: (topicId: number, articleId: number) => `/app/topic/${topicId}/article/${articleId}`,
    INDIVIDUAL_SUPPORT_DEF: "individual-support",
    INDIVIDUAL_SUPPORT: "/app/individual-support",
    SUPPORT_QUESTIONNAIRE_DEF: "support-questionnaire",
    SUPPORT_QUESTIONNAIRE: "/app/support-questionnaire",
    ACCOUNT_INFO_DEF: "account-info",
    ACCOUNT_INFO: "/app/account-info",
    CHAT_DEF: "chat/:ticketId",
    CHAT: (ticketId: number) => `/app/chat/${ticketId}`,
    WORKLIFE_REQUEST_DEF: "worklife-request",
    WORKLIFE_REQUEST: "/app/worklife-request",

    FAQS: "/faqs",
    CONTACT_US: "/contact-us",
    PRIVACY_POLICY: "/privacy-policy",
    TERMS_OF_SERVICE: "/terms-of-service",
    CALL_PAGE: "/call-page",
    INVALID_LINK: "/invalid-link",
    SYSTEM_CHECK: "/system-check",
    TEST: "/test",
    ERROR: "/error",
    NOT_FOUND: "*",
} as const;
